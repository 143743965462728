import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Container, Typography } from "@mui/material";

// Interface for form values
interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

// Props interface for ChangePassword component
interface ChangePasswordProps {
  buttonText?: string;
  buttonColor?: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  buttonText,
  buttonColor,
}) => {
  // State variables to handle password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Formik form handling
  const formik = useFormik<FormValues>({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      oldPassword: yup.string().required("Current Password is required"),
      newPassword: yup
        .string()
        .required("New Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])(.{8,})$/,
          "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character from !@#$%^&*()"
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), undefined], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        // Your API request logic using fetch goes here
        const response = await fetch(
          process.env.REACT_APP_CHANGE_PASSWORD_API || "",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: process.env.REACT_APP_AUTH_TOKEN || "",
            },
            body: JSON.stringify({
              old_password: values.oldPassword,
              password: values.newPassword,
              password2: values.confirmPassword,
            }),
          }
        );

        if (response.status === 200) {
          toast.success("Password changed successfully");
          resetForm();
        } else if (response.status === 400) {
          toast.error("Old password is not correct, please try again.");
        } else if (response.status === 401) {
          toast.error(response.statusText);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      } catch (error) {
        console.error("An error occurred. Please try again later.", error);
      }
    },
  });

  // Functions to toggle password visibility
  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Change Password
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        {/* Old Password field */}
        <TextField
          fullWidth
          id="oldPassword"
          name="oldPassword"
          label="Current Password"
          type={showOldPassword ? "text" : "password"}
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
          }
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleOldPasswordVisibility}>
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* New Password field */}
        <TextField
          fullWidth
          id="newPassword"
          name="newPassword"
          label="New Password"
          type={showNewPassword ? "text" : "password"}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleNewPasswordVisibility}>
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* Confirm Password field */}
        <TextField
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          sx={{ background: buttonColor }}
          color="primary"
        >
          {buttonText || "Change Password"}
        </Button>
      </form>
    </Container>
  );
};

export default ChangePassword;
