import ForgotPasswordButton from "./forgotPasswordButton";

const example = () => {
  return (
    <>
      {/* <ForgotPasswordButton title={"Reset Password"} buttonColor={"blue"} /> */}
      <ForgotPasswordButton title={"Reset Password"} />
    </>
  );
};

export default example;
