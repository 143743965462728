import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./home";
import SendFeedback from "./components/send_feedback/example";
import AccountNotificationSettings from "./components/account-notification-setting/notificationSettings";
import WebInputField from "./components/web-input-field/example";
import ForgotResetPassword from "./components/forgot_reset_password/example";
import ChangePassword from "./components/change_password/example";
import UserProfile from "./components/profile_features/example";
import UsersList from "./components/user_list/usersList";
import AccoutDelete from "./components/account_delete_logout_confirmation_popup/example";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<Home />} />
      <Route
        path="/account-notification-settings"
        element={<AccountNotificationSettings />}
      />
      <Route path="/send-feedback" element={<SendFeedback />} />
      <Route path="/web-input-fields" element={<WebInputField />} />
      <Route path="/forgot-reset-password" element={<ForgotResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/user-profile" element={<UserProfile />} />
      <Route path="/user-list" element={<UsersList />} />
      <Route path="/account-delete" element={<AccoutDelete />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
