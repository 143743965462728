import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./forgotResetPassword.module.css";

// Props interface for OtpEntryForm component
interface OtpEntryFormProps {
  open: boolean; // Indicates whether the OTP entry form is open
  email: string; // Email for which OTP is being entered
  onClose: () => void; // Callback function to close the OTP entry form
  onOtpConfirm: (otp: string) => void; // Callback function on successful OTP confirmation
  otpErrorMessage: string | null; // Error message related to OTP, if any
}

// Form values interface for validation
interface OtpEntryFormValues {
  otp: string; // OTP input field value
}

// OtpEntryForm component
const OtpEntryForm: React.FC<OtpEntryFormProps> = ({
  open,
  email,
  onClose,
  onOtpConfirm,
  otpErrorMessage,
}) => {
  // Formik configuration for OTP entry form
  const formik = useFormik<OtpEntryFormValues>({
    initialValues: {
      otp: "",
    } as OtpEntryFormValues,
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^\d{6}$/, "Enter a valid 6-digit OTP")
        .required("Please enter OTP"),
    }),
    onSubmit: (values: OtpEntryFormValues) => {
      // Callback on successful OTP confirmation
      onOtpConfirm(values.otp);
    },
  });

  // Reset the form when the OTP entry form is opened
  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open]);

  // Render the OTP entry form
  return (
    <Dialog open={open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <DialogTitle>
        Enter OTP
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={styles["close-button"]}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Display instructions and email information */}
        <Typography variant="body1" gutterBottom>
          A verification email has been sent to {email}. Please check your email
          and enter the OTP.
        </Typography>
        {/* OTP entry form */}
        <form onSubmit={formik.handleSubmit}>
          <Box p={2}>
            {/* OTP input field */}
            <TextField
              label="OTP"
              type="text"
              name="otp"
              value={formik.values.otp}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={
                // Display OTP validation error or OTP related error message, if any
                formik.touched.otp && formik.errors.otp ? (
                  <Typography color="error" variant="body2">
                    {formik.errors.otp}
                  </Typography>
                ) : (
                  otpErrorMessage && (
                    <Typography color="error" variant="body2">
                      {otpErrorMessage}
                    </Typography>
                  )
                )
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              margin="normal"
            />
            {/* Confirm OTP button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Confirm OTP
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OtpEntryForm;
