import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ConfirmationBox from "./confirmationBox";

type ConfirmationButtonProps = {
  title: string;
  onConfirm: () => void;
  confirmationText?: string;
  rejectionText?: string;
  buttonColor?: string;
};

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  title,
  onConfirm,
  confirmationText,
  rejectionText,
  buttonColor,
}) => {
  const [confirmation, setConfirmation] = useState({
    open: false,
  });

  const handleOpenConfirmation = () => {
    setConfirmation({
      open: true,
    });
  };
  const handleCloseConfirmation = () => {
    setConfirmation({
      open: false,
    });
  };
  const handleConfirm = () => {
    onConfirm();
    handleCloseConfirmation();
  };

  return (
    <Box className="pageContainer">
      <Button
        style={{
          backgroundColor: buttonColor,
          color: "white",
          margin: "2px",
        }}
        onClick={() => handleOpenConfirmation()}
      >
        {title}
      </Button>
      <ConfirmationBox
        title={`Are you sure you want to ${title.toLowerCase()}?`}
        open={confirmation.open}
        onReject={handleCloseConfirmation}
        onAccept={handleConfirm}
        confirmationText={confirmationText || "Yes"}
        rejectionText={rejectionText || "No"}
      />
    </Box>
  );
};

export default ConfirmationButton;
