import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";

function App() {
  return (
    <>
      <ToastContainer />
      <Box display="flex" justifyContent="center">
        <Typography variant="h4" gutterBottom>
          Welcome to Web Modules Demo Page
        </Typography>
      </Box>
      <Divider />
      <Outlet />
    </>
  );
}

export default App;
