import ConfirmationButton from "./confirmationButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccountSettings = () => {
  const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN || "";
  const DELETE_ACCOUNT_API = process.env.REACT_APP_DELETE_ACCOUNT_API || "";
  const LOGOUT_USER_API = process.env.REACT_APP_LOGOUT_USER_API || "";

  const handleDeleteUser = async () => {
    try {
      // Add delete logic here
      const response = await fetch(DELETE_ACCOUNT_API, {
        method: "DELETE",
        headers: {
          Authorization: AUTH_TOKEN,
        },
      });

      if (response.ok) {
        toast.success("User deleted successfully.");
      } else {
        toast.error("Failed to delete user.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting user.");
    }
  };

  const handleLogout = async () => {
    try {
      // Add logout logic here
      const response = await fetch(LOGOUT_USER_API, {
        method: "POST",
        headers: {
          Authorization: AUTH_TOKEN,
        },
      });

      if (response.ok) {
        toast.success("User logged out successfully.");
      } else {
        toast.error("Failed to log out user.");
      }
    } catch (error) {
      console.error("Error logging out user:", error);
      toast.error("An error occurred while logging out user.");
    }
  };

  return (
    <>
      <ConfirmationButton
        title="Delete"
        onConfirm={handleDeleteUser}
        confirmationText={"Delete"}
        rejectionText={"Cancel"}
        buttonColor="red"
      />
      <ConfirmationButton
        title="Logout"
        onConfirm={handleLogout}
        buttonColor="blue"
      />
    </>
  );
};

export default AccountSettings;
