import React, { useState } from "react";
import EmailEntryForm from "./emailEntryForm";
import { Button } from "@mui/material";
import styles from "./forgotResetPassword.module.css";

// Props interface for ForgotPasswordButton component
interface ForgotPasswordButtonProps {
  title?: string; // Button title
  buttonColor?: string; // Button background color
}

// ForgotPasswordButton component
const ForgotPasswordButton: React.FC<ForgotPasswordButtonProps> = ({
  title,
  buttonColor,
}) => {
  // State to manage the email entry form's open/close status
  const [emailEntryForm, setEmailEntryForm] = useState({
    open: false,
  });

  // Function to open the email entry form
  const handleOpenEmailEntryForm = () => {
    setEmailEntryForm({
      open: true,
    });
  };

  // Function to close the email entry form
  const handleCloseEmailEntryForm = () => {
    setEmailEntryForm({
      open: false,
    });
  };

  // Function to handle confirmation action
  const handleConfirm = () => {
    handleCloseEmailEntryForm();
  };

  // Render the ForgotPasswordButton component
  return (
    <>
      {/* Button triggering the email entry form */}
      <Button
        className={styles["forgot-password-button"]}
        sx={{ background: buttonColor || "red" }}
        onClick={() => handleOpenEmailEntryForm()}
      >
        {title || "Forgot Password"}
      </Button>
      {/* EmailEntryForm component */}
      <EmailEntryForm
        open={emailEntryForm.open}
        onClose={handleCloseEmailEntryForm}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ForgotPasswordButton;
