import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  FormControlLabel,
  Switch,
  FormGroup,
  Container,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

interface NotificationSettingsProps {
  id: number | null;
  push_notification: boolean;
  email_notification: boolean;
}

const API_URL = process.env.REACT_APP_API_URL || "";
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN || "";

const NotificationSettings: React.FC = () => {
  const formik = useFormik<NotificationSettingsProps>({
    initialValues: {
      id: null,
      push_notification: false,
      email_notification: false,
    },
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${API_URL}${values.id}/`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: AUTH_TOKEN,
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          toast.success("Settings updated successfully.");
        } else {
          console.log(response);
          toast.error(response.statusText);
        }
      } catch (error) {
        console.error("Error updating backend:", error);
      }
    },
  });

  const onMount = async () => {
    try {
      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          Authorization: AUTH_TOKEN,
        },
      });
      if (response.ok) {
        const data = await response.json();
        formik.setValues(data);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    formik.submitForm();
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Account Notification Settings
      </Typography>
      <form>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.push_notification}
                onChange={handleChange}
                name="push_notification"
              />
            }
            label="Push Notifications"
          />
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.email_notification}
                onChange={handleChange}
                name="email_notification"
              />
            }
            label="Email Notifications"
          />
        </FormGroup>
      </form>
    </Container>
  );
};

export default NotificationSettings;
