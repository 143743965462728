import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Box, Button, Typography } from "@mui/material";
import * as Yup from "yup";
import InputField from "./inputField";
import countryNames from "../../assets/Countries";
import PersonIcon from "@mui/icons-material/Person";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneCallbackOutlinedIcon from "@mui/icons-material/PhoneCallbackOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { toast } from "react-toastify";
import "./inputField.css";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  age: number;
  country: string;
  password: string;
  confirmPassword: string;
}

const complexPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
const nameRegex = /^[a-zA-Z\s]+$/;

const ModuleInputField: React.FC = () => {
  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dob: "",
      age: 0,
      country: "",
      password: "",
      confirmPassword: "",
    } as FormValues,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter first name")
        .matches(
          nameRegex,
          "Please enter a valid name containing only alphabets and spaces."
        ),
      lastName: Yup.string()
        .required("Please enter last name")
        .matches(
          nameRegex,
          "Please enter a valid name containing only alphabets and spaces."
        ),
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address")
        .matches(emailRegex, "Please enter a valid email address."),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Invalid phone number")
        .required("Please enter phone number"),
      dob: Yup.date()
        .required("Please enter date of birth")
        .min(new Date("1950-01-01"), "Date of birth can't be before 1950-01-01")
        .max(new Date(), "Date of birth can't be in the future"),
      age: Yup.number()
        .required("Age is required")
        .positive("Age must be a positive number"),
      country: Yup.string().required("Please select the country"),
      password: Yup.string()
        .required("Password is Required")
        .matches(
          complexPasswordRegex,
          "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character (@ $ ! % * ? &)"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Passwords must match")
        .required("Confirm password is Required"),
    }),
    onSubmit: (values: FormValues, { resetForm }) => {
      toast.success("Form Submitted Successfully.");
      console.log("form submitted", values);
      resetForm();
    },
  });

  const [countries, setCountries] = useState<string[]>([]);
  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setCountries(countryNames);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <Typography variant="h4" mt={6} mb={6}>
        The module-input-field module can be used in the following ways
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ m: 2 }}>
        <InputField
          label="First Name"
          type="text"
          name="firstName"
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //   description="Enter your first name"
          fieldIcon={<PersonIcon />}
        />
        <InputField
          label="Last Name"
          type="text"
          name="lastName"
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //   description="Enter your last name"
          fieldIcon={<PersonIcon />}
        />
        <InputField
          label="Email"
          type="email"
          name="email"
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //   description="Enter a valid email address"
          fieldIcon={<EmailOutlinedIcon />}
        />
        <InputField
          label="Phone Number"
          type="number"
          name="phone"
          value={formik.values.phone}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //   description="Enter a valid Indian phone number"
          fieldIcon={<PhoneCallbackOutlinedIcon />}
        />
        <InputField
          label="Date of Birth"
          type="date"
          name="dob"
          value={formik.values.dob}
          error={formik.touched.dob && Boolean(formik.errors.dob)}
          helperText={formik.touched.dob && formik.errors.dob}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //   description="Enter your date of birth"
          fieldIcon={<CakeOutlinedIcon />}
          inputProps={{
            max: new Date().toISOString().split("T")[0], // Set maximum date to today
          }}
        />
        <InputField
          label="Age"
          type="number"
          name="age"
          value={formik.values.age}
          error={formik.touched.age && Boolean(formik.errors.age)}
          helperText={formik.touched.age && formik.errors.age}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputProps={{
            min: 0,
          }}
        />
        <InputField
          inputProps={{ className: "input--select" }}
          label="Country"
          type="select"
          name="country"
          value={formik.values.country}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
          onChange={handleCountryChange}
          onBlur={formik.handleBlur}
          options={countries}
          fieldIcon={<PublicOutlinedIcon />}
        />
        <InputField
          label="Password"
          type="password"
          name="password"
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fieldIcon={<KeyOutlinedIcon />}
          showPassword={showPassword}
          togglePassword={togglePassword}
          description="Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character (@ $ ! % * ? &)"
        />
        <InputField
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showPassword={showConfirmPassword}
          togglePassword={toggleConfirmPassword}
          fieldIcon={<KeyOutlinedIcon />}
          description="Confirm passowrd should be same as password "
        />
        <Button variant="contained" type="submit" sx={{ m: 1 }}>
          Send
        </Button>
      </Box>
    </>
  );
};

export default ModuleInputField;
