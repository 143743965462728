import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  value: string | number;
  error: boolean | undefined;
  helperText: string | false | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  description?: string;
  options?: string[];
  fieldIcon?: React.ReactNode;
  showPassword?: boolean;
  togglePassword?: () => void;
  inputProps?: object;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  description,
  options,
  fieldIcon,
  showPassword,
  togglePassword,
  inputProps, // Spread remaining props
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const handleDescriptionClick = () => {
    setShowDescription(!showDescription);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <TextField
        label={label}
        type={showPassword ? "text" : type}
        variant="outlined"
        fullWidth
        sx={{ m: 1 }}
        name={name}
        onChange={onChange}
        value={value}
        error={error}
        onBlur={onBlur}
        helperText={helperText}
        select={type === "select"}
        InputProps={{
          startAdornment: fieldIcon && (
            <InputAdornment position="start">{fieldIcon}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ marginRight: "20px" }}>
              {description && (
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 7,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={handleDescriptionClick}
                >
                  <InfoIcon />
                </IconButton>
              )}
              {type === "password" && togglePassword && (
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 35,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={togglePassword}
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={inputProps}
      >
        {type === "select" &&
          options &&
          options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </TextField>
      {showDescription && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: 0, ml: 2 }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default InputField;
