import FeedbackForm from "./feedbackForm";

const example = () => {
  return (
    <>
      {/* <FeedbackForm /> */}
      <FeedbackForm submitButtonText="Submit" />
    </>
  );
};

export default example;
