import { Dialog, DialogTitle, Button, Typography, Box } from "@mui/material";
type ConfirmationBoxProps = {
  open: boolean;
  title?: string;
  confirmationText: string;
  rejectionText: string;
  onAccept: () => void;
  onReject: () => void;
};
const ConfirmationBox: React.FC<ConfirmationBoxProps> = ({
  open,
  title,
  confirmationText,
  rejectionText,
  onAccept,
  onReject,
}: ConfirmationBoxProps) => {
  return (
    <Dialog maxWidth="xs" fullWidth open={open} disableEscapeKeyDown>
      <Box p={2}>
        <DialogTitle>
          {title && (
            <Typography variant="h3" textAlign="center" color="black">
              {title}
            </Typography>
          )}
        </DialogTitle>

        <Box p={2}>
          <Box>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={onAccept}
            >
              {confirmationText}
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onReject}
            >
              {rejectionText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationBox;
