import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Avatar,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import "./userProfile.css"; // Import CSS file
import { toast } from "react-toastify";

const UserProfile = () => {
  // State to manage edit mode
  const [isEditing, setIsEditing] = useState(false);
  const [selProfilePic, setProfilePic] = useState<File | null>(null);
  // State to hold user details
  const [profileDetails, setProfileDetails] = useState<any>({});
  // State to keep track of whether data has been fetched or not
  const [dataFetched, setDataFetched] = useState(false);

  // API to fetch user details
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_VIEW_PROFILE || "", {
        method: "GET",
        headers: {
          Authorization: process.env.REACT_APP_AUTH_TOKEN || "",
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProfileDetails(data);
        setDataFetched(true); // Indicate that data has been fetched
      } else {
        console.error("Failed to fetch user details:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  //set form values from data fetched
  useEffect(() => {
    formik.setValues({
      firstName: profileDetails?.user?.first_name || "",
      lastName: profileDetails?.user?.last_name || "",
      email: profileDetails?.user?.email || "",
      address: profileDetails?.address || "",
      gender: profileDetails?.gender || "",
      dateOfBirth: profileDetails?.date_of_birth || "",
    });
  }, [profileDetails, dataFetched, isEditing]);

  // Minimum age constant
  const MINIMUM_AGE = 18;
  const nameRegex = /^[a-zA-Z\s]+$/;

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(
        nameRegex,
        "Please enter a valid name containing only alphabets and spaces."
      ),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(
        nameRegex,
        "Please enter a valid name containing only alphabets and spaces."
      ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    address: Yup.string().required("Address is required"),
    gender: Yup.string().required("Gender is required"),
    dateOfBirth: Yup.date()
      .max(
        new Date(
          new Date().getFullYear() - MINIMUM_AGE,
          new Date().getMonth(),
          new Date().getDate()
        ),
        `Must be at least ${MINIMUM_AGE} years old`
      )
      .required("Date of Birth is required")
      .nullable(),
  });

  // Initialize formik hook
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      gender: "",
      dateOfBirth: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("user.first_name", values.firstName);
      formData.append("user.last_name", values.lastName);
      // formData.append('user.email', values.email);
      formData.append("address", values.address);
      formData.append("gender", values.gender);
      formData.append("date_of_birth", values.dateOfBirth);
      if (selProfilePic) {
        formData.append("photo", selProfilePic);
      }
      try {
        const response = await fetch(
          process.env.REACT_APP_EDIT_PROFILE + profileDetails?.id + "/" || "",
          {
            method: "PATCH",
            headers: {
              Authorization: process.env.REACT_APP_AUTH_TOKEN || "",
            },
            body: formData,
          }
        );
        if (response.ok) {
          console.log("Profile updated successfully");
          setIsEditing(false);
          fetchUserDetails(); // Fetch updated user details after submission
          toast.success("Profile details saved successfully");
        } else {
          console.error("Failed to update profile:", response.statusText);
          toast.error("Failed to update profile");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("Failed to update profile:");
      }
    },
  });

  console.log(formik.values);

  // Handle save action
  const handleSave = () => {
    formik.handleSubmit();
  };

  // Handle cancel action
  const handleCancel = () => {
    formik.resetForm();
    setIsEditing(false);
    setProfilePic(null);
  };
  // Handle profile picture change
  const HandleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePic(event.target.files[0]);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Paper className="paper-container">
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            {isEditing ? (
              <div className="avatar-container">
                {!selProfilePic && (
                  <Avatar
                    alt="Profile Photo"
                    src={profileDetails?.photo}
                    className="avatar"
                  >
                    {formik.values.firstName.charAt(0)}
                  </Avatar>
                )}
                {selProfilePic && (
                  <Avatar
                    alt="Profile Photo"
                    src={URL.createObjectURL(selProfilePic)}
                    className="avatar"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={HandleProfilePicChange}
                  style={{ display: "none" }} // Hide the file input visually
                  id="profilePicInput"
                />
                <label htmlFor="profilePicInput">
                  <Button variant="contained" color="primary" component="span">
                    Add Photo
                  </Button>
                </label>
              </div>
            ) : (
              <Avatar
                alt="Profile Photo"
                src={profileDetails?.photo}
                className="avatar"
              >
                {formik.values.firstName.charAt(0)}
              </Avatar>
            )}
          </Grid>
          <Grid item className="full-width"></Grid>
          <Grid item className="half-width">
            <TextField
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              disabled={!isEditing}
              fullWidth
            />
          </Grid>
          <Grid item className="half-width">
            <TextField
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              disabled={!isEditing}
              fullWidth
            />
          </Grid>
          <Grid item className="full-width">
            <TextField
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item className="full-width">
            <TextField
              name="address"
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              disabled={!isEditing}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item className="half-width">
            <FormControl fullWidth>
              <TextField
                label="Gender"
                select
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                disabled={!isEditing}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </TextField>
            </FormControl>
            {formik.touched.gender && formik.errors.gender && (
              <Typography color="error">{formik.errors.gender}</Typography>
            )}
          </Grid>
          <Grid item className="half-width">
            <TextField
              type="date"
              name="dateOfBirth"
              label="Date of Birth"
              value={formik.values.dateOfBirth}
              onChange={formik.handleChange}
              error={
                formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
              }
              helperText={
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              }
              disabled={!isEditing}
              fullWidth
              InputLabelProps={{ shrink: true }} // Ensure label floats above input even if it's empty
              inputProps={{
                max: new Date(
                  new Date().getFullYear() - 18,
                  new Date().getMonth(),
                  new Date().getDate()
                )
                  .toISOString()
                  .split("T")[0],
              }}
            />
          </Grid>
          <Grid item className="full-width">
            {/* Edit and save buttons */}
            <Box className="save-cancel-buttons">
              {isEditing ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserProfile;
