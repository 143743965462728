import React, { useState, useEffect } from "react";
import axios from "axios";
import { Column, useTable } from "react-table";
import {
  TextField,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  InputLabel,
  IconButton,
  Button,
} from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import styles from "./userLists.module.css";

interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  // is_active: boolean;
  date_joined: string;
}

interface ApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  current_page: number;
  items_per_page: number;
  total_pages: number;
  results: User[];
}

const UsersList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterParams, setFilterParams] = useState<any>({});
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isActiveFilter, setIsActiveFilter] = useState<boolean | null>(null);
  const [sorting, setSorting] = useState<{
    column: string;
    order: "asc" | "desc";
  }>({ column: "", order: "asc" });

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get<ApiResponse>(
          process.env.REACT_APP_USER_LIST_API || "",
          {
            headers: {
              Authorization: process.env.REACT_APP_AUTH_TOKEN || "",
            },
            params: {
              search: searchTerm,
              ...filterParams,
              ordering:
                sorting.order === "asc" ? sorting.column : `-${sorting.column}`,
              is_active:
                isActiveFilter !== null ? isActiveFilter.toString() : undefined,
              page: currentPage,
              page_size: pageSize,
            },
          }
        );
        setUsers(response.data.results);
        setTotalItems(response.data.count);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [
    searchTerm,
    filterParams,
    isActiveFilter,
    sorting,
    currentPage,
    pageSize,
  ]);

  // Handler for changing page size
  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value as number);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  // Handler for navigating to previous page
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handler for navigating to next page
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "is_active") {
      setIsActiveFilter(
        value === "true" ? true : value === "false" ? false : null
      );
    } else {
      setFilterParams((prevParams: any) => ({
        ...prevParams,
        [name]: value,
      }));
    }
  };

  const handleSortChange = (column: string) => {
    setSorting((prevSorting) => ({
      column,
      order:
        prevSorting.column === column && prevSorting.order === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const columns: Column<User>[] = React.useMemo(
    () => [
      { Header: "Username", accessor: "username" },
      { Header: "Email", accessor: "email" },
      { Header: "First Name", accessor: "first_name" },
      { Header: "Last Name", accessor: "last_name" },
      // {
      //   Header: "Active",
      //   accessor: "is_active",
      //   Cell: ({ value }: { value: boolean }) => {
      //     return (
      //       <span className={value ? "active" : "inactive"}>
      //         {value ? "Yes" : "No"}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: "Date Joined",
        accessor: "date_joined",
        Cell: ({ value }: { value: string }) => {
          const formattedDate = new Date(value).toLocaleDateString();
          return <span>{formattedDate}</span>;
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: users });

  return (
    <div className={styles["userListsContainer"]}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ width: "45%" }}>
          <TextField
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            fullWidth
          />
        </Box>
        {/* <Box sx={{ width: "45%" }}>
          <Box display="flex" justifyContent="space-between">
            <FormControl fullWidth>
              <InputLabel id="filter">Filter</InputLabel>
              <Select
                labelId="filter"
                name="is_active"
                label="Sort By"
                onChange={handleFilterChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box> */}
      </Box>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  sx={{ fontWeight: "bold" }}
                >
                  {column.render("Header")}
                  <IconButton onClick={() => handleSortChange(column.id)}>
                    {sorting.column === column.id ? (
                      sorting.order === "asc" ? (
                        <NorthIcon fontSize="small" />
                      ) : (
                        <SouthIcon fontSize="small" />
                      )
                    ) : (
                      <ImportExportIcon />
                    )}
                  </IconButton>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                No data available
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Prev
          </Button>
          <span>
            {currentPage} / {totalPages}
          </span>
          <Button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <InputLabel>Items per page:</InputLabel>
          <Select value={pageSize} onChange={handlePageSizeChange}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Box>
      </Box>
    </div>
  );
};

export default UsersList;
