import UserProfile from "./userProfile";
import "react-toastify/dist/ReactToastify.css";

function example() {
  return (
    <>
      <UserProfile />
    </>
  );
}

export default example;
