import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, List, ListItem, ListItemText } from "@mui/material";

const Home: React.FC = () => {
  return (
    <>
      <List>
        <ListItem component={RouterLink} to="/send-feedback">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                1. Send Feedback{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/account-notification-settings">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                3. Account Notification Settings{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/web-input-fields">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                5. Text Input Component{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/account-delete">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                7. Account Settings - Logout, Delete Account ({" "}
                <span style={{ color: "red" }}>**</span> This will delete the
                user token and redeployment required){" "}
                <span style={{ color: "green" }}>(QA Verified)</span>{" "}
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/forgot-reset-password">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                8. Account Settings - Forgot / Reset Password{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>{" "}
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/user-profile">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                9. User Profile Feature{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>{" "}
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/change-password">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                10. Account Settings - Change Password{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>{" "}
              </Typography>
            }
          />
        </ListItem>
        <ListItem component={RouterLink} to="/user-list">
          <ListItemText
            primary={
              <Typography variant="body1" sx={linkStyles}>
                11. Search/Filters/Sort Feature with List of Users{" "}
                <span style={{ color: "green" }}>(QA Verified)</span>{" "}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </>
  );
};

const linkStyles = {
  color: "black",
  fontSize: "1.2rem",
  textDecoration: "none",
};

export default Home;
