import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Box, Typography, Container } from "@mui/material";
import { toast } from "react-toastify";
import styles from "./sendFeedback.module.css";

interface FeedbackFormProps {
  // Add any props you may need
  submitButtonText?: string; // Optional prop for customizing submit button text
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ submitButtonText }) => {
  // Use Formik for form management
  const formik = useFormik({
    // Set initial form values
    initialValues: {
      name: "John Doe", // Placeholder for logged-in user's name
      email: "john@example.com", // Placeholder for logged-in user's email
      title: "",
      message: "",
    },
    // Define form validation using Yup schema
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      message: Yup.string().required("Message is required"),
    }),
    // Define form submission behavior
    onSubmit: async ({ title, message }, { resetForm }) => {
      try {
        // Make API request to submit feedback
        const response = await fetch(
          process.env.REACT_APP_SEND_FEEDBACK_API || "",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: process.env.REACT_APP_AUTH_TOKEN || "",
            },
            body: JSON.stringify({ subject: title, message }),
          }
        );

        // Parse response data
        const data = await response.json();
        console.log("Feedback submitted successfully:", data);

        // Display success message
        toast.success("Feedback submitted successfully.");

        // Reset form
        resetForm();
      } catch (error) {
        // Handle errors during feedback submission
        console.error("Error submitting feedback:", error);

        // Display error message
        toast.error("Error submitting feedback.");
      }
    },
  });

  return (
    <Container maxWidth="md" className={styles["form-container"]}>
      {/* Form Header */}
      <Typography variant="h4" component="h2" gutterBottom>
        Send Feedback
      </Typography>
      {/* Feedback Form */}
      <form onSubmit={formik.handleSubmit}>
        {/* User Name Input (Disabled) */}
        <Box mt={2.5}>
          <TextField
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            disabled
            fullWidth
          />
        </Box>
        {/* User Email Input (Disabled) */}
        <Box mt={2.5}>
          <TextField
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            disabled
            fullWidth
          />
        </Box>
        {/* title Input */}
        <Box mt={2.5}>
          <TextField
            id="title"
            name="title"
            label="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            fullWidth
          />
        </Box>
        {/* Message Input */}
        <Box mt={2.5}>
          <TextField
            id="message"
            name="message"
            label="Message"
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            fullWidth
          />
        </Box>
        {/* Submit Button */}
        <Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles["form-submit-button"]}
          >
            {submitButtonText || "Submit Feedback"}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default FeedbackForm;
