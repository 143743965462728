import ChangePassword from "./changePassword";

const example = () => {
  return (
    <>
      {/* <ChangePassword buttonColor="red" buttonText="Submit" /> */}
      <ChangePassword buttonText="Submit" />
    </>
  );
};

export default example;
