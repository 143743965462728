import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./forgotResetPassword.module.css";

// Props interface for PasswordEntryForm component
interface PasswordEntryFormProps {
  open: boolean; // Indicates whether the password entry form is open
  onClose: () => void; // Callback function to close the password entry form
  onPasswordSubmit: (password: string) => void; // Callback function on successful password submission
}

// Form values interface for validation
interface PasswordEntryFormValues {
  password: string; // Password input field value
  confirmPassword: string; // Confirm password input field value
}

// PasswordEntryForm component
const PasswordEntryForm: React.FC<PasswordEntryFormProps> = ({
  open,
  onClose,
  onPasswordSubmit,
}) => {
  // Formik configuration for password entry form
  const formik = useFormik<PasswordEntryFormValues>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter a password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character (@ $ ! % * ? &)"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: (values) => {
      // Callback on successful password submission
      onPasswordSubmit(values.password);
    },
  });

  // State for toggling password visibility
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  // Reset the form when the password entry form is opened
  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open]);

  // Render the password entry form
  return (
    <Dialog open={open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <DialogTitle>
        Password Entry
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={styles["close-button"]}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box p={2}>
        <DialogContent>
          {/* Password input field */}
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            {...formik.getFieldProps("password")}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* Toggle button for password visibility */}
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          {/* Confirm password input field */}
          <TextField
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            {...formik.getFieldProps("confirmPassword")}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* Toggle button for confirm password visibility */}
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          {/* Submit button */}
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              formik.handleSubmit();
            }}
            sx={{ marginTop: 2 }}
          >
            Submit
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default PasswordEntryForm;
