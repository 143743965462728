import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import OtpEntryForm from "./otpEntryForm";
import PasswordEntryForm from "./passwordEntryForm";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./forgotResetPassword.module.css";

// Define the props for the EmailEntryForm component
interface EmailEntryFormProps {
  open: boolean; // Indicates whether the dialog is open
  onClose: () => void; // Callback function to close the dialog
  onConfirm: () => void; // Callback function on successful confirmation (not used in the provided code)
}

// Define the form values interface for validation
interface FormValues {
  email: string; // Email input field value
}

// EmailEntryForm component
const EmailEntryForm: React.FC<EmailEntryFormProps> = ({ open, onClose }) => {
  // State variables
  const [isLoading, setIsLoading] = React.useState(false);

  // Models errors for email and OTP
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<
    string | null
  >(null);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState<string | null>(
    null
  );

  // Models state for OTP and Password Entry forms
  const [otpEntryFormOpen, setOtpEntryFormOpen] = React.useState(false);
  const [passwordEntryFormOpen, setPasswordEntryFormOpen] =
    React.useState(false);

  // Managing state for OTP model response
  const [otpToken, setOtpToken] = React.useState<string | null>(null);
  const [uid, setUid] = React.useState<string | null>(null);

  // Environment variables
  const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN || "";
  const REACT_APP_EMAIL_ENTRY_API = process.env.REACT_APP_EMAIL_ENTRY_API || "";
  const REACT_APP_PASSWORD_ENTRY_API =
    process.env.REACT_APP_PASSWORD_ENTRY_API || "";
  const REACT_APP_OTP_ENTRY_API = process.env.REACT_APP_OTP_ENTRY_API || "";

  // Formik configuration
  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    } as FormValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address"),
    }),
    onSubmit: async (values: FormValues) => {
      try {
        setIsLoading(true);
        // Send a request to the email entry API
        const response = await fetch(REACT_APP_EMAIL_ENTRY_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AUTH_TOKEN,
          },
          body: JSON.stringify(values),
        });

        if (response.status === 200) {
          // Success: Open OTP entry form
          setEmailErrorMessage(null);
          setOtpErrorMessage(null);
          setOtpEntryFormOpen(true);
        } else if (response.status === 404) {
          // Email not found: Show error message
          const errorData = await response.json();
          setEmailErrorMessage(
            errorData.error ||
              "The e-mail address is not assigned to any user account"
          );
        } else {
          // Other error: Display a generic error message
          toast.error("An error occurred. Please try again later.");
        }
      } catch (error) {
        // Catch any unexpected errors
        toast.error("An error occurred. Please try again later.");
      } finally {
        // Set loading state to false
        setIsLoading(false);
      }
    },
  });

  // Handle OTP confirmation
  const handleOtpConfirm = async (otp: string) => {
    try {
      // Send OTP verification request
      const otpVerificationResponse = await fetch(REACT_APP_OTP_ENTRY_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AUTH_TOKEN,
        },
        body: JSON.stringify({
          email: formik.values.email,
          otp: otp,
        }),
      });

      if (otpVerificationResponse.status === 200) {
        // OTP verification successful: Proceed to password entry
        const responseData = await otpVerificationResponse.json();
        setOtpToken(responseData.token);
        setUid(responseData.uid);
        setOtpEntryFormOpen(false);
        setPasswordEntryFormOpen(true);
      } else if (otpVerificationResponse.status === 400) {
        // OTP verification failed: Show error message
        const errorData = await otpVerificationResponse.json();
        setOtpErrorMessage(errorData.error || "OTP verification failed");
      } else {
        // Other error during OTP verification
        console.error("An error occurred during OTP verification.");
      }
    } catch (error) {
      // Catch any unexpected errors during OTP verification
      console.error("An error occurred during OTP verification:", error);
    }
  };

  // Handle password submission
  const handlePasswordSubmit = async (password: string) => {
    try {
      // Prepare payload for password change request
      const payload = {
        new_password1: password,
        new_password2: password,
        token: otpToken,
        uid: uid,
      };
      const response = await fetch(REACT_APP_PASSWORD_ENTRY_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AUTH_TOKEN,
        },
        body: JSON.stringify(payload),
      });
      if (response.status === 200) {
        // Password changed successfully
        toast.success("Password changed successfully!");
        onClose();
      } else {
        // Error changing password: Show error message
        const errorData = await response.json();
        toast.error(errorData);
      }
    } catch (error) {
      // Catch any unexpected errors during password change
      console.error("An error occurred during password change:", error);
    } finally {
      // Close the password entry form
      setPasswordEntryFormOpen(false);
    }
  };

  // Reset form when modal opens
  useEffect(() => {
    formik.resetForm();
    setEmailErrorMessage(null);
  }, [open]);

  // Render the EmailEntryForm component
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          Forgot Password
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={styles["close-button"]}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box p={2} component="form" onSubmit={formik.handleSubmit}>
          <DialogContent>
            {/* Email input field */}
            <TextField
              label="Email"
              type="email"
              name="email"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* Submit button for sending OTP */}
            <Button
              className={styles["send-otp-button"]}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? "Sending OTP..." : "Send OTP"}
            </Button>
            {/* Display error message for email, if any */}
            {emailErrorMessage && (
              <div className={styles["error-message"]}>{emailErrorMessage}</div>
            )}
          </DialogContent>
        </Box>
      </Dialog>
      {/* OTP Entry Form */}
      <OtpEntryForm
        open={otpEntryFormOpen}
        email={formik.values.email}
        onClose={() => setOtpEntryFormOpen(false)}
        onOtpConfirm={handleOtpConfirm}
        otpErrorMessage={otpErrorMessage}
      />
      {/* Password Entry Form */}
      <PasswordEntryForm
        open={passwordEntryFormOpen}
        onClose={() => setPasswordEntryFormOpen(false)}
        onPasswordSubmit={handlePasswordSubmit}
      />
    </>
  );
};

export default EmailEntryForm;
